import React, {Fragment} from "react";

class Footer extends React.Component{
    render() {
        return (
            <Fragment>
                <div className="p-3 bg-dark text-white text-center mt-3 row animated slideInLeft 1s">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3>HAPPY BIRTHDAY SANDHYA (MUSU)</h3>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default Footer;
