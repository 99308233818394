import './bootstrap/css/font-awesome-5.8.1.css';
import 'mdbootstrap/css/bootstrap.css';
import 'mdbootstrap/css/mdb.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';


ReactDOM.render(
  <React.StrictMode>
    <App/>
    
  </React.StrictMode>,
  document.getElementById('root')
);

